import Vue from "vue";
import vueSmoothScroll from "vue-smoothscroll";
import Contact from "./contact";
import Recruit from "./recruit";
import "./animation";

Vue.use(vueSmoothScroll);

new Vue({
  el: "#app",
  mixins: [Contact, Recruit],
  data: {
    modal_active: false,
    banner_active: true,
    header_active: false,
    lastPoint: 0,
  },
  created: function () {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleScrollBanner);
  },
  methods: {
    clickSmoothScroll(target) {
      this.$SmoothScroll(
        document.querySelector("#" + target),
        600,
        null,
        null,
        "y"
      );
    },
    clickScrollTop() {
      this.$SmoothScroll(0, 600, null, null, "y");
    },
    handleScroll() {
      const scrollY = window.scrollY;
      const windowHieght = window.innerHeight;
      const scrollBottom = scrollY + windowHieght;

      const target_top = $("body").offset().top;
      const target_height = $("body").innerHeight();

      if (scrollY > 100) {
        this.header_active = true;
      } else {
        this.header_active = false;
      }

      const speed = 200;
      if (scrollY + windowHieght >= target_top + target_height) {
        $("#bannerSimulator").fadeOut(speed);
        this.banner_active = false;
      } else {
        $("#bannerSimulator").fadeIn(speed);
        this.banner_active = true;
      }
    },
    handleScrollBanner() {
      const scrollPoint = window.scrollY;

      if (scrollPoint < this.lastPoint || this.lastPoint == 0) {
        // 下スクロールの場合
        this.banner_active = true;
      } else {
        // 上スクロールの場合
        this.banner_active = false;
      }

      this.lastPoint = scrollPoint;
    },
  },
});

/* eslint-disable no-undef */
$(function () {
  /* $(".indexNews__slide").on(
    "init reInit afterChange",
    function (event, slick, currentSlide, nextSlide) {
      const current = (currentSlide ? currentSlide : 0) + 1;
      const page = slick.slideCount;
      let indicator_html = "";
      for (let i = 0; i < page; i++) {
        if (current > i) {
          indicator_html += `<span class="current"></span>`;
        } else {
          indicator_html += `<span></span>`;
        }
      }
      $(".indexNews__indicator").html(indicator_html);
    }
  ); */
  $(".indexNews__slide").slick({
    slidesToScroll: 1,
    /* dots: true, */
    variableWidth: true,
    appendArrows: $(".indexNews__arrows"),
    prevArrow: `<button type="button" class="slick-prev"><span></span></button>`,
    nextArrow: `<button type="button" class="slick-next"><span></span></button>`,

    /* responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 1,
        },
      },
    ], */
  });

  $(".cloth__slide").slick({
    variableWidth: true,
    arrows: true,
    centerMode: false,
    appendArrows: $(".close_arrows"),
    prevArrow: `<button type="button" class="slick-prev"><span></span></button>`,
    nextArrow: `<button type="button" class="slick-next"><span></span></button>`,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
        },
      },
    ],
  });

  const styleSlide_count = $(".styleList__item").length;

  const styleList_option =
    styleSlide_count > 3
      ? {
          accessibility: true,
          swipe: true,
          infinite: true,
          arrows: true,
        }
      : {
          accessibility: false,
          swipe: false,
          infinite: false,
          arrows: false,
          mobileFirst: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                accessibility: true,
                swipe: true,
                infinite: true,
                arrows: true,
              },
            },
          ],
        };

  if (styleSlide_count > 0) {
    $(".styleList").slick({
      //...styleList_option,
      ...{
        slidesToShow: 3,
        centerPadding: "0px",
        centerMode: false,
        appendArrows: $("#styleList_arrows"),
        prevArrow: `<button type="button" class="slick-prev"><span></span></button>`,
        nextArrow: `<button type="button" class="slick-next"><span></span></button>`,
        mobileFirst: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              centerPadding: "5%",
            },
          },
        ],
      },
    });
  }

  $(".toggle_btn").on("click", function () {
    $(this).toggleClass("is-open");
    $(this).parent().find(".toggle_content").stop().slideToggle();
  });

  $(".archive-toggle").on("click", function () {
    $(this).toggleClass("is-open");
    $(this).next().stop().slideToggle();
  });

  $(window).scroll(function () {
    $(".txt-bg").each(function (i) {
      const scrollY = window.scrollY;
      const windowHieght = window.innerHeight;
      const scrollBottom = scrollY + windowHieght;

      const target_top = $(this).offset().top;
      //const target_height = $(this).innerHeight();

      const target_pos = (
        (scrollBottom - target_top) /
        (target_top + windowHieght - target_top)
      ).toFixed(3);
      let per = Math.max(0, target_pos);
      per = Math.min(1, per);
      $(this).css({ transform: `translateX(${3 * per}%)` });
    });

    $(".indexStore__img img").each(function (i) {
      const scrollY = window.scrollY;
      const windowHieght = window.innerHeight;
      const scrollBottom = scrollY + windowHieght;

      const target_top = $(this).offset().top;
      const target_height = $(this).innerHeight();

      //0 -6
      //1 0
      //12x -6

      //0 0
      //1 -6
      //console.log(scrollY);

      //console.log(target_top + windowHieght - target_top);

      //console.log(scrollY / (target_top + target_height));

      //console.log(scrollY - target_top + target_height);

      const target_pos = (
        (scrollBottom - target_top) /
        (target_top + windowHieght - target_top)
      ).toFixed(3);
      //console.log(target_pos);
      let per = Math.max(0, target_pos);
      per = Math.min(1, per);

      const target_pos2 = (scrollY / (target_top + target_height)).toFixed(3);

      let per2 = Math.max(0, target_pos2);
      //per2 = Math.min(1, per);
      //console.log(per2);

      $(this).css({ transform: `translateX(${-6 * 3 * per2 + 0}%)` });
    });
  });

  $(".inview").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).addClass("viewed");
    }
  });
  $(".inviewTit").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).addClass("viewed");
    }
  });
  $(".inviewTxt").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).addClass("viewed");
    }
  });
  $(".inviewBox").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).addClass("viewed");
    }
  });
});

/* $(window).on("load", function () {
  $(".pageMain").addClass("loaded");
}); */

$(document).ready(function () {
  //$(".pageMain").addClass("loaded");
  //$(".indexMain").addClass("loaded");
});

$(function () {
  $(".pageMain").addClass("loaded");
});
