$(function () {
  if (1 != sessionStorage.getItem("access") && $("#splash").length > 0) {
    animate();
    document.addEventListener("touchmove", disableScroll, { passive: false });
    document.addEventListener("mousewheel", disableScroll, { passive: false });
  } else {
    $("#splash").remove();
    //$("body").removeClass("fixed");
    $(".indexMain").addClass("loaded");
  }

  sessionStorage.setItem("access", 1);
  //テスト用
  //sessionStorage.setItem("access", 0);

  //animate();

  function disableScroll(event) {
    event.preventDefault();
  }

  function animate() {
    const splash_logo = document.querySelectorAll(".splash__logo svg path");

    $("#splash").removeClass("hide");

    //window.addEventListener("resize", resizeAnime);

    const anime_logo = anime({
      targets: splash_logo,
      duration: 1000,
      delay: 3400,
      easing: "linear",
      fill: ["transparent", "#ffffff"],
      begin: function () {
        $("#app").addClass("isFirst");
        $(".splash__logo svg").addClass("start");
        $(".splash__overlay").addClass("slide");
      },
      complete: function (anim) {
        if (anim.completed) {
          $(window).scrollTop(0);
          $("#splash").fadeOut(1000);
          $(".splash__overlay").addClass("fadeOut");
          $("#app").removeClass("isFirst");
          $("#indexMv").addClass("loadedFirst");

          document.removeEventListener("touchmove", disableScroll, {
            passive: false,
          });
          document.removeEventListener("mousewheel", disableScroll, {
            passive: false,
          });
        }
      },
    });

    /* function resizeAnime() {
      if ($("#splash").length == 0) {
        return;
      }
      anime_logo.restart();
    } */
  }
});
$(document).ready(function () {});
