import axios from "axios";
import "url-search-params-polyfill";
import "es6-promise/auto";

export default {
  data: function () {
    return {
      is_confirm: false,
      contact: {
        name: "",
        tel: "",
        email: "",
        email2: "",
        content: "",
      },
      errors: {
        name: "",
        tel: "",
        email: "",
      },
      isError: false,
    };
  },
  created: function () {},
  mounted: function () {},
  methods: {
    check_name: function () {
      const name = this.contact.name.trim();

      if (name.length === 0) {
        this.errors.name = "お名前を入力してください";
        this.isError = true;
      } else {
        this.errors.name = "";
      }
    },

    check_tel: function () {
      const tel = this.contact.tel.trim();
      console.log(tel.length);
      if (tel.length > 0 && !tel.match(/^[0-9０-９\-ー]*$/)) {
        this.errors.tel = "お電話番号を正しく入力してください";
        this.isError = true;
      } else {
        this.errors.tel = "";
      }
    },

    check_email: function () {
      const email = this.contact.email.trim();
      const email2 = this.contact.email2.trim();

      if (email.length === 0 || email2.length === 0) {
        this.errors.email =
          "メールアドレス、メールアドレス（確認用）を入力してください";
        this.isError = true;
      } else if (email !== email2) {
        this.errors.email =
          "メールアドレスとメールアドレス（確認用）が異なります";
        this.isError = true;
      } else if (
        !email.match(
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        )
      ) {
        this.errors.email =
          "メールアドレス、メールアドレス（確認用）を正しく入力してください";
        this.isError = true;
      } else {
        this.errors.email = "";
      }
    },
    check_content: function () {
      const content = this.contact.content.trim();

      if (content.length === 0) {
        this.errors.content = "お問い合わせ内容を入力してください";
        this.isError = true;
      } else {
        this.errors.content = "";
      }
    },
    confirm: function () {
      this.isError = false;
      this.check_name();
      this.check_tel();
      this.check_email();
      if (!this.isError) {
        console.log("遷移");
        this.is_confirm = true;
        this.clickSmoothScroll("contact");
        //this.clickScrollTop();
      }
    },

    submit: function () {
      const params = new URLSearchParams();
      Object.keys(this.contact).forEach(function (value) {
        params.append(value, this[value]);
      }, this.contact);

      params.append("category", "contact");

      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      instance
        .post("/assets/modules/form.php", params)
        .then(function (response) {
          location.href = "./fin.html";
        })
        .catch(function (error) {})
        .finally(function () {});
    },
  },
};
