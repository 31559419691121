import axios from "axios";
import "url-search-params-polyfill";
import "es6-promise/auto";

export default {
  data: function () {
    return {
      is_confirm: false,
      recruit: {
        name: "",
        age: "",
        email: "",
        email2: "",
        content: "",
      },
      recruit_errors: {
        name: "",
        email: "",
      },
      isError: false,
    };
  },
  created: function () {},
  mounted: function () {},
  methods: {
    check_recruit_name: function () {
      const name = this.recruit.name.trim();

      if (name.length === 0) {
        this.recruit_errors.name = "お名前を入力してください";
        this.isError = true;
      } else {
        this.recruit_errors.name = "";
      }
    },

    check_recruit_age: function () {
      const age = this.recruit.age.trim();

      if (age.length === 0) {
        this.recruit_errors.age = "";
      } else if (!age.match(/^[0-9]+$/)) {
        this.isError = true;
        this.recruit_errors.age = "半角数値のみご入力ください";
      } else {
        this.recruit_errors.age = "";
      }
    },

    check_recruit_email: function () {
      const email = this.recruit.email.trim();
      const email2 = this.recruit.email2.trim();

      if (email.length === 0 || email2.length === 0) {
        this.recruit_errors.email =
          "メールアドレス、メールアドレス（確認用）を入力してください";
        this.isError = true;
      } else if (email !== email2) {
        this.recruit_errors.email =
          "メールアドレスとメールアドレス（確認用）が異なります";
        this.isError = true;
      } else if (
        !email.match(
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        )
      ) {
        this.recruit_errors.email =
          "メールアドレス、メールアドレス（確認用）を正しく入力してください";
        this.isError = true;
      } else {
        this.recruit_errors.email = "";
      }
    },
    check_recruit_content: function () {
      const content = this.recruit.content.trim();

      if (content.length === 0) {
        this.recruit_errors.content = "お問い合わせ内容を入力してください";
        this.isError = true;
      } else {
        this.recruit_errors.content = "";
      }
    },
    recruit_confirm: function () {
      this.isError = false;
      this.check_recruit_name();
      //this.check_recruit_age();
      this.check_recruit_email();
      if (!this.isError) {
        console.log("遷移");
        this.is_confirm = true;
        this.clickSmoothScroll("recruitAbout");
      }
    },

    recruit_submit: function () {
      const params = new URLSearchParams();
      Object.keys(this.recruit).forEach(function (value) {
        params.append(value, this[value]);
      }, this.recruit);

      params.append("category", "recruit");

      console.log(params);

      const instance = axios.create({
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      instance
        .post("/assets/modules/form.php", params)
        .then(function (response) {
          location.href = "./fin.html";
        })
        .catch(function (error) {})
        .finally(function () {});
    },
  },
};
